.manage-media-house-wrapper {
    margin: 2%;
}

.ant-input-number {
    width: 100%;
}

.add-media-house-btn {
    position: absolute;
    right: 24px;
    bottom: 24px;
}

.add-media-house-btn:hover {
    cursor: pointer;
}
