.ant-layout-header {
    background-color: rgb(1, 29, 17);
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-logo {
    height: 64px;
}
