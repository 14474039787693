.manage-artists-wrapper {
    margin: 2%;
}

.add-artist-btn {
    position: absolute;
    right: 24px;
    bottom: 24px;
}

.add-artist-btn:hover {
    cursor: pointer;
}
