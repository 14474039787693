html, body {
    height: 100%;
    min-height: 100%;
}

#root {
    height: 100%;
    min-height: 100%;
    display: grid;
}

#root > .ant-layout {
    height: 100%;
    min-height: 100%;
}

#root > .login-form {
    width: 50%;
    margin: auto;
    padding: 12px 48px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}

button[type=submit] {
    display: block;
    width: 100%;
    margin: 3% auto;
    color: #fff;
    background-color: #eb1c2b;
    border-color: #eb1c2b;
    font-weight: 600;
}

button[type=submit]:hover {
    color: #fff;
    background-color: #eb1c2b;
    border-color: #eb1c2b;
}

.logo {
    height: 125px;
    display: block;
    margin: 0 auto;
}
