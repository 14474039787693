.add-content-btn {
    display: block;
    width: 75%;
    margin: 5% auto;
    color: #fff;
    background-color: rgb(243, 101, 35);
    border-color: rgb(243, 101, 35);
}

.add-content-btn:hover {
    color: #fff;
    background-color: rgb(243, 101, 35);
    border-color: rgb(243, 101, 35);
}