.manage-genres-wrapper {
    margin: 2%;
}

.add-genre-btn {
    position: absolute;
    right: 24px;
    bottom: 24px;
}

.add-genre-btn:hover {
    cursor: pointer;
}
